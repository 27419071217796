@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.form-control {
    height: 40px !important; 
    width: 120px !important;
    margin: 0 !important;
  }
  /* .react-tel-input {
    margin-top: 10px !important;
    margin-left: 10px !important;
  } */

